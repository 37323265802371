<template>
  <div>
    <template v-for="product in products">
      <ProductCard
        :key="product.id"
        :product="product"
        :selected="selectedProductId === product.id"
        @click.native="setSelectedProductId(product.id)"
      />
    </template>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex'
import ProductCard from '@md/components/ProductCard'

export default {
  components: {
    ProductCard
  },
  props: {
    products: Array
  },
  computed: {
    ...mapState('fieldRecordSystem/products', [
      'selectedProductId'
    ])
  },
  methods: {
    ...mapMutations('fieldRecordSystem/products', [
      'setSelectedProductId'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
