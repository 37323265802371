<template>
  <div class="product-management">
    <FrsRouterBackLink target="orgDashboard">
      <template #text>
        <IxRes>frs.products.buttons.BackToDashboard</IxRes>
      </template>
    </FrsRouterBackLink>
    <div class="management-controls">
      <h3>
        <IxRes>masterData.productManagement</IxRes>
      </h3>
      <ToggleButtons
        v-model="selectedGroup"
        class="toggles"
      />
      <ProductList
        class="list"
        :products="filteredAndSortedProducts"
      />
      <Footer
        class="footer"
        @add="onAddProduct"
        @edit="onEditProduct"
        @archive="$refs.archiveModal.start({...selectedProduct})"
      />
    </div>
    <AsyncConfirmationModal
      ref="archiveModal"
      translation-namespace="frs.product"
      action="archiveProduct"
      @confirm="onArchiveProduct"
    >
      <template #default="{name}">
        <IxRes :context="{name}">
          frs.products.delete.question
        </IxRes>
      </template>
    </AsyncConfirmationModal>
  </div>
</template>

<script>
import {sortBy} from 'lodash'
import {mapState, mapActions, mapGetters} from 'vuex'
import {mapFormFields, mapResources} from '@helpers/vuex'

import {notifications} from 'src/js/infrastructure'

import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'

import Footer from './Footer'
import ProductList from './ProductList'
import ToggleButtons from './ToggleButtons'
import AsyncConfirmationModal from '@components/modals/AsyncConfirmationModal'

export default {
  components: {
    FrsRouterBackLink,
    Footer,
    ProductList,
    ToggleButtons,
    AsyncConfirmationModal
  },
  computed: {
    ...mapState('masterData', [
      'customProducts'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId'
    ]),
    ...mapFormFields('fieldRecordSystem/products', [
      'selectedGroup',
      'selectedProductId'
    ]),
    ...mapResources([
      'Common.SR_Common',
      'Messages.State.SR_StateMessages'
    ]),
    filteredAndSortedProducts () {
      return sortBy(Object.values(this.products).filter(p => p.type === this.selectedGroup), 'name')
    },
    selectedProduct () {
      return this.products[this.selectedProductId]
    },
    products () {
      return this.customProducts[this.orgUnitId]
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/products', [
      'deleteProduct',
      'loadPrerequisiteData',
      'reset'
    ]),
    async onArchiveProduct () {
      this.isLoading = true
      try {
        await this.deleteProduct(this.selectedProductId)
        notifications.success(this.SR_StateMessages.DeleteSuccess)
      } catch (error) {
        notifications.error(this.SR_StateMessages.DeleteError)
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    onEditProduct () {
      this.$router.push({name: 'product-details', params: {productId: this.selectedProductId}})
    },
    onAddProduct () {
      this.$router.push({name: 'product-details'})
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'product-details') {
        vm.loadPrerequisiteData()
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    this.reset()
    next()
  }
}
</script>

<style lang="scss" scoped>
.product-management {
  display: flex;
  flex-direction: column;
  height: 100%;

  .management-controls {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    padding: 5px;

    .list {
      overflow: auto;
      flex: 1;
    }

  }
}
</style>
