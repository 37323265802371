<template>
  <div>
    <ToggleButton
      v-model="selected" :value="'seed'"
      required
    >
      <IxRes>masterData.seed</IxRes>
    </ToggleButton>
    <ToggleButton
      v-model="selected" :value="'fertilizer'"
      required disabled
    >
      <IxRes>masterData.fertilization</IxRes>
    </ToggleButton>
    <ToggleButton
      v-model="selected" :value="'protection'"
      required disabled
    >
      <IxRes>masterData.protection</IxRes>
    </ToggleButton>
  </div>
</template>

<script>
import ToggleButton from '@components/ToggleButton'

export default {
  components: {
    ToggleButton
  },
  props: {
    value: String
  },
  computed: {
    selected: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
