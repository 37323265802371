<template>
  <div>
    <IxButton
      remove
      large
      :disabled="!selectedProductId"
      @click="$emit('archive')"
    />
    <IxButton
      edit large
      :disabled="!selectedProductId"
      @click="$emit('edit')"
    />
    <IxButton
      add large
      @click="$emit('add')"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import IxButton from 'src/vue/components/IxButton'

export default {
  components: {
    IxButton
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/products', [
      'selectedProductId'
    ])
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
